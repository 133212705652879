import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';

import { THE_BRIDE } from '@/constants';
import { BG_DESKTOP_VIEW } from '@/constants/assets';

import useInvitation from '@hooks/useInvitation';

const TEXT_SHADOW = 'rgba(12,12,12,.8)';

function LeftContent() {
  const isInvitation = useInvitation();

  return (
    <Box
      bgColor="bgPrimary"
      width="calc(100% - 500px)"
      height="100%"
      pos="fixed"
      bgImage={BG_DESKTOP_VIEW}
      bgSize="cover"
      padding="42px"
      borderRight="8px solid"
      borderRightColor="blackAlpha.400"
    >
      <WithAnimation>
        <Text
          letterSpacing="2px"
          fontSize="md"
          color="mainColorText"
          textShadow={`1px 1px 4px ${TEXT_SHADOW}`}
        >
          {isInvitation ? 'THE WEDDING OF' : 'WEDDING ANNOUNCEMENT'}
        </Text>
      </WithAnimation>
      <WithAnimation>
        <Heading
          margin="32px 0"
          fontWeight="normal"
          size="4xl"
          letterSpacing="2px"
          color="mainColorText"
          textShadow={`1px 1px 6px ${TEXT_SHADOW}`}
          dangerouslySetInnerHTML={{ __html: THE_BRIDE }}
        />
      </WithAnimation>
      <WithAnimation>
        <Text
          fontStyle="italic"
          maxWidth="500px"
          color="mainColorText"
          letterSpacing="1px"
          textShadow={`1px 1px 4px ${TEXT_SHADOW}`}
        >
          {`“Pernikahan mengingatkan kita bahwa hidup kita memiliki makna dan bahwa cinta adalah ikatan yang terkuat, sukacita yang paling bahagia, dan penyembuhan terindah yang pernah kita alami.” `}
          <br />– Daphne Rose Kingma
        </Text>
      </WithAnimation>
    </Box>
  );
}

export default LeftContent;
