import imgCover from '../images/assets/cover.jpg';
import imgCountdown from '../images/assets/countin-days.jpg';
import imgFooter from '../images/assets/footer.jpg';
import imgBride from '../images/assets/bride.jpg';
import imgGroom from '../images/assets/groom.jpg';
import imgW1 from '../images/assets/welcoming-1.jpg';
import imgW2 from '../images/assets/welcoming-2.jpg';
import imgW3 from '../images/assets/welcoming-3.jpg';
import imgW4 from '../images/assets/welcoming-4.jpg';
import imgW5 from '../images/assets/welcoming-5.jpg';
import imgG1 from '../images/assets/gallery-1.jpg';
import imgG2 from '../images/assets/gallery-2.jpg';
import imgG3 from '../images/assets/gallery-3.jpg';

export const BG_COVER = imgCover;
export const BG_COUNTING = imgCountdown;
export const BG_FOOTER = imgFooter;
export const BG_LIVESTREAM = ``;
export const BG_LOVESTORY = `https://user-images.githubusercontent.com/10141928/149857523-805f43ad-0c1b-4300-b72c-3f8e8d37a1f8.jpg`;
export const BG_GIFTCARD = ``;
export const BG_DESKTOP_VIEW = imgW1;

export const IMG_LAMPION = `https://user-images.githubusercontent.com/10141928/150158769-8c451a18-4b83-46fe-97d9-3fc8f87f7e06.png`;
// images welcome section
export const IMG_WELCOME_1 = imgW1;
export const IMG_WELCOME_2 = imgW2;
export const IMG_WELCOME_3 = imgW3;
export const IMG_WELCOME_4 = imgW4;
export const IMG_WELCOME_5 = imgW5;
// couple image
export const IMG_MAN = imgGroom;
export const IMG_GIRL = imgBride;
export const IMG_PHOTO_COVER = `https://user-images.githubusercontent.com/10141928/150639548-4e4b612b-35c4-4fda-88d7-fa3cdd97295d.png`;
export const IMG_LOGO_WEDDING = `https://user-images.githubusercontent.com/10141928/150645258-2dddbcd4-a473-4d90-b2f5-825d0fe1737e.png`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/149857534-17748784-0c8a-451a-80e0-941e2798927f.jpg`;
export const IMG_LOGO = ``;

// video assets
export const VIDEO_COVER = `https://user-images.githubusercontent.com/10141928/261929068-8e3d824c-54e0-4bcb-a27e-f9bac0c45a6c.mp4`;
// image photo gallery cover
export const IMG_PHOTO_1 = imgG1;
export const IMG_PHOTO_2 = imgG2;
export const IMG_PHOTO_3 = imgG3;
// image icons
export const IMG_ICON_HAND = `https://user-images.githubusercontent.com/10141928/150661877-d975b35b-3e55-4333-b216-2f9df6ca2584.png`;
export const SONG_CDN_URL = `https://res.cloudinary.com/invitato/video/upload/v1640358887/template-song/LeeHi_-_ONLY_Lyrics_nzuosa.mp4`;

export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/10141928/152914092-4dfb40e5-9552-49dc-a2d4-72a345f08b06.png`,
  en: `https://user-images.githubusercontent.com/10141928/158618733-55b6141e-dc5a-4a6e-a65e-953cb09127dc.jpg`,
};

export const LOGO_INVITATO = `https://user-images.githubusercontent.com/10141928/150662036-816aabf8-76f9-4342-bc47-3d7892362753.png`;

//Collaboration
export const LOGO_VENDOR_DALANG = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-dalang_bp5JgCazz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651639908257`;
export const LOGO_VENDOR_PENTONE_WHITE = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-pentone-white_4cMUJafwM.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654518552744`;
export const LOGO_VENDOR_PENTONE_BLACK =
  'https://user-images.githubusercontent.com/103095433/193169607-562d8316-835d-4c15-a6b4-2b3c7cd22621.png';
export const LOGO_VENDOR_TIRTOWENING =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-tirtowening_2o6jmuIlN.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911787';
export const LOGO_VENDOR_SAMAWA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-samawa_KniSfe28i.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911529';
export const LOGO_VENDOR_SADEWA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-sadewa_6J3D8PmsJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911185';
export const LOGO_VENDOR_PRAHASTA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-prahasta-white_5L4WQTBlc.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910828';
export const LOGO_VENDOR_FLATTER =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-flatter_SvHO8RoVF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910005';
export const LOGO_VENDOR_AKSARA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-aksara-white_Ofc6Xkib-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708909659';
export const LOGO_VENDOR_DREAMWORKS =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-dreamworks-white_FFlUUi8Dk.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910073';
